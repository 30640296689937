<template>
    <div>
        <section v-if="dataLoading" class="about py-lg-9">
            <div class="text-center pt-7 pb-7">
                <loading-animation class="text-primary text-5x"/>
            </div>
        </section>
        <div v-else class="container font-inter-regular fs-norm align-justify py-6">
            <div class="row">
                <div class="col-12">
                    <heading-with-info :bottom-tag="true" title="Services"
                                       info="(Unlocking Opportunities, Securing Futures)"></heading-with-info>
                </div>
                <div v-for="(item, i) in data" :key="i" class="col-12">
                    <div v-if="i % 2 !== 0 && i!==0" class="px-4 px-md-0 px-lg-0">
                        <div class="card-1 row mt-md-6">
                            <div class="col-md-6">
                                <div class="px-lg-3 mt-md-4 mt-lg-0">
                                    <img src="../assets/web/feature1.jpg" class="img-fluid img-shadow">
                                </div>
                            </div>
                            <div class=" col-md-6 font-manrope-regular mb-4 mb-lg-0">
                                <h3 class="font-inter-semi-bold">{{item.title}}</h3>
                                <p class="p1 mt-3 pr-lg-6 font-inter-medium">{{item.content}}</p>
                                <ul class="list-unstyled font-inter-medium text-primary mt-4">
                                    <li class="mt-2 fl-x fl-a-c">{{item.feature_1}}<span class="ml-2"><img
                                        src="../assets/web/icons/arrow-leaned.svg" alt=""></span></li>
                                    <li class="mt-2 fl-x fl-a-c">{{item.feature_2}}<span class="ml-2"><img
                                        src="../assets/web/icons/arrow-leaned.svg" alt=""></span></li>
                                    <li class="mt-2 fl-x fl-a-c">{{item.feature_3}} <span class="ml-2"><img
                                        src="../assets/web/icons/arrow-leaned.svg" alt=""></span></li>
                                    <li v-if="item.feature_4" class="mt-2 fl-x fl-a-c">{{item.feature_4}} <span class="ml-2"><img
                                        src="../assets/web/icons/arrow-leaned.svg" alt=""></span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div v-else class="px-4 px-md-0 px-lg-0">
                        <div class="row mt-lg-6 mt-6">
                            <div class="col-md-6 order-2 order-lg-1 font-manrope-regular">
                                <div class="px-lg-2 mt-md-4 mt-lg-0 mt-3 mb-4 mb-lg-0">
                                    <h3 class="font-inter-semi-bold">{{item.title}}</h3>
                                    <p class=" parag2 font-inter-medium mt-3">{{item.content}}</p>
                                    <ul class="list-unstyled font-inter-medium text-primary mt-4">
                                        <li class="mt-2 fl-x fl-a-c">{{item.feature_1}}<span class="ml-2"><img
                                            src="../assets/web/icons/arrow-leaned.svg" alt=""></span></li>
                                        <li class="mt-2 fl-x fl-a-c">{{item.feature_2}}<span class="ml-2"><img
                                            src="../assets/web/icons/arrow-leaned.svg" alt=""></span></li>
                                        <li class="mt-2 fl-x fl-a-c">{{item.feature_3}} <span class="ml-2"><img
                                            src="../assets/web/icons/arrow-leaned.svg" alt=""></span></li>
                                        <li v-if="item.feature_4" class="mt-2 fl-x fl-a-c">{{item.feature_4}} <span class="ml-2"><img
                                            src="../assets/web/icons/arrow-leaned.svg" alt=""></span></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 order-1 order-lg-2">
                                <div class="pr-lg-6 pb-lg-2 mt-md-4 mt-lg-0">
                                    <img src="../assets/web/feature1.jpg" class="img-fluid img-shadow">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeadingWithInfo from '@components/HeadingWithInfo';

export default {
    name: 'ServicesPage',
    components: { HeadingWithInfo },
    data () {
        return {
            details: '',
            dataLoading: false,
            data: [
                {
                    title: 'Expert Consultation ',
                    content: 'Our expert team ensures seamless property transactions through meticulous title scrutiny, accurate valuation, and precise documentation services, providing you with confidence and clarity in every step of your post bid winning journey.',
                    feature_1: 'Property Title Scrutiny ',
                    feature_2: 'Valuation of the property ',
                    feature_3: 'Documentation services '
                },
                {
                    title: 'Financing Assistance',
                    content: 'With our dedicated team of financial experts, we\'re committed to delivering dynamic and tailored financial solutions that align with your goals and empower your financial success.',
                    feature_1: 'Mortgage/Purchase Financing ',
                    feature_2: 'Bridge Financing ',
                    feature_3: 'Unsecured Funding  '
                },
                {
                    title: 'Dedicated Relationship Managers',
                    content: 'Enjoy the trusted ally with our team of Relationship Managers specialized in curating a seamless experience from identifying prime listings to managing the intricacies of sale and purchase transactions of properties/assets on auction. ',
                    feature_1: 'Identification of the property ',
                    feature_2: 'Acquisition of property  ',
                    feature_3: 'Sale of property  ',
                    feature_4: 'Wealth/ Property Management  '
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
